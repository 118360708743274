import { hookstate, State, useHookstate } from '@hookstate/core';
import { devtools } from '@hookstate/devtools';
import React from 'react';
import { FullPageSpinner } from '../Common/FullPageSpinner';
import { AuthService, Roles } from '../Services/AuthService';
import { IUserProfile } from '../Services/CommonService';
import { ApiUtility } from '../Utilities/ApiUtility.axios';
import { InMemoryJWT } from '../Utilities/InMemoryJWT';
import { Utils } from '../Utilities/Utils';




export enum UserStatus {
    Active = 0,
    DeActive = 1
}

interface IUserContextState {
    loaded: boolean;
    user: Partial<IUserProfile>;
    status?: UserStatus;
}
interface IUserAppConfigState {
    dashboard: string;
    striped: boolean;
    pageLength: number;    
}

export const PAGE_LENGTH = 15;
const DefaultAppConfig: IUserAppConfigState = {
    dashboard: 'dashboard',
    striped: false,
    pageLength: PAGE_LENGTH,
};

export const stateUserContext = hookstate<IUserContextState>(
    {
        loaded: false,
        user: {},
    },
    devtools({ key: 'user-context-state' })
);
export const stateUserAppConfig = hookstate<IUserAppConfigState>(
    DefaultAppConfig,
    devtools({ key: 'user-app-config-state' })
);


const wrapUserContextState = (s: State<IUserContextState>, sConfig: State<IUserAppConfigState>) => {


    const isAuthenticated = () => {        
        const user = s.user.get();
        if (AuthService.getAuthToken() && user && user.id) return true;
        return false;
    };

  

    const isInRole = (roles: string | string[]) => {  
        let exists = false;
        if (isAuthenticated()) {
            const uRoles = s.user.roles.get();
            if (uRoles) {
                if (typeof roles === 'string') exists = uRoles.includes(roles);
                else exists = uRoles.some((v) => roles.includes(v));
            }
        }
        return exists;
    };
    
    const setCurrentUser = (user?: IUserProfile) => {
        if (user) {
            s.merge({ user: user, status: UserStatus.Active, loaded: true });
        } else {
            s.merge({ user: {}, status: UserStatus.DeActive, loaded: true });
        }
    };

    return {        
        isAuthenticated,
        isInRole,                
        getUser: () => (isAuthenticated() ? s.user.get() : undefined),
        getUserStatus: () => (isAuthenticated() ? s.status.get() : undefined),
        getUserId: () => (isAuthenticated() ? s.user.id.get() : ''),        
        getUserName: () => (isAuthenticated() ? s.user.fullName.get() : ''),
        getUserThumbUrl: () => (isAuthenticated() ? s.user.thumbUrl.get() : ''),
        getUserRoles: (): string[] =>
            (isAuthenticated() ? s.user.roles.get({ noproxy: true }) : []) as any,              
        currentUser: s.user,
        isAdmin: () => isInRole(Roles.Admin),
        isSuperAdmin: () => isInRole(Roles.SuperAdminC),    
        isTeamLeader: () => isInRole(Roles.TeamLeader),
        setCurrentUser,
        stateUserContext,   
    };
};

export const useUser = () =>
    wrapUserContextState(useHookstate(stateUserContext), useHookstate(stateUserAppConfig));

interface IUserContextProps {     
    getUserDetailsFromServer: () => Promise<void>;
}

export const UserContext = React.createContext<IUserContextProps>({} as any);

export function UserProvider(props: { children?: any }) {  
  
    const state = useHookstate(stateUserContext);
    const getUserDetailsFromServer = async () => {
        let user = Utils.getLocal('ActingOffice.Admin.Token');
        // let user = Utils.getSession('tp-user');
        if (!user) {
            user = await ApiUtility.getResult(`/auth/profile`, {});            
            Utils.setLocal('ActingOffice.Admin.Token', user);
            // Utils.setSession('tp-user', user);
        }
        if (user) {
            state.merge({
                user: user,
                status: UserStatus.Active,
                loaded: true,
            });;
        } else state.merge({ loaded: true, user: {} });
    };

    React.useEffect(() => {        
        const fetchData = async () => {            
            const authToken = await InMemoryJWT.getRefreshedToken(true);
            if (authToken) {
                getUserDetailsFromServer();
            } else {
                state.merge({ loaded: true });
            }
        };
        fetchData();
        return () => {
            InMemoryJWT.abortRefreshToken();
            console.debug('User context unloaded');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!state.loaded.value) {        
        return <FullPageSpinner label='Loading...' />;                
    }
    //console.debug('user-context rendered');
    return (
        <UserContext.Provider
            value={{                
                getUserDetailsFromServer,
            }}
            {...props}
        />
    );
}

export function useUserContext() {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error(`useUserContext must be used within a UserProvider`);
    }
    return context;
}

