import { Menu } from "antd";
import { useEffect, useState } from "react";
import "./MainLayout.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../Context/UserContext";
import {
  DiffTwoTone,
  LayoutTwoTone,
  ProfileTwoTone,
  ScheduleTwoTone,
  ShoppingTwoTone,
} from "@ant-design/icons";
// import { useCompany } from "../Context/CompanyContext";

const Sidemenus: React.FC<{
  isCollapse: any;
  onsetSlide: any;
  styles: any;
  isSlide: any;
}> = (props: any) => {
  let loc = useLocation();
  const { isAuthenticated, isSuperAdmin } = useUser();
  // const { isFeatureExists } = useCompany();
  const [current, setCurrent] = useState(
    loc.pathname === "/" ? "/" : `/${loc.pathname.split("/")[1]}`
  );
  useEffect(() => {
    if (loc) {
      if (current !== loc.pathname) {
        setCurrent(`/${loc.pathname.split("/")[1]}`);
      }
    }
  }, [loc, current]);
  const navigate = useNavigate();
  const setSelectedMenuItem = (key: any) => {
    if (key) {
      navigate(key);
      props.onsetSlide(false);
      setCurrent(key);
    }
  };

  return (
    <>
      {isAuthenticated() && isSuperAdmin() ? (
        <Menu
          theme="light"
          style={props?.isSlide && props?.styles}
          mode="inline"
          className="sidebar"
          defaultSelectedKeys={[current]}
          selectedKeys={[current]}
          onClick={(e) => setSelectedMenuItem(e.key)}
          items={[
            {
              key: "/dashboard",
              icon: <LayoutTwoTone />,
              label: <Link to="/dashboard">Dashboard</Link>,
            },
            {
              key: "/practices",
              icon: <ShoppingTwoTone />,
              label: <Link to="/practices">Practices</Link>,
            },
            {
              key: "/updates",
              icon: <ProfileTwoTone />,
              label: <Link to="/updates">Updates</Link>,
            },
            {
              key: "/ctconfiguration",
              icon: <ScheduleTwoTone />,
              label: <Link to="/ctconfiguration">CTConfiguration</Link>,
            },
            {
              key: "/payrollyearlysettings",
              icon: <DiffTwoTone />,
              label: (
                <Link to="/payrollyearlysettings">Payroll Yearly Settings</Link>
              ),
            },
            // {
            //   key: "/sa100configuration",
            //   icon: <SnippetsTwoTone />,
            //   label: <Link to="/sa100configuration">SA100 Configuration</Link>,
            // },
          ]}
        />
      ) : null}
    </>
  );
};
export default Sidemenus;
