import { notification } from 'antd';
import React from 'react';
import {  Route, Routes, useLocation } from 'react-router-dom';
import Practices from '../Components/Practices/Practices';
import Dashboard from '../Components/Dashboard/Dashboard';
import PracticeDetails from '../Components/Practices/PracticeDetails';
import Updates from '../Components/Updates/Updates';
import CTConfiguration from '../Components/CTConfiguration/CTConfiguration';
import Profile from '../Components/Profile/Profile';
import PayrollYearlySettings from '../Components/PayrollYearlySettings/PayrollYearlySettings';
import PayrollYearlySettingsDetails from '../Components/PayrollYearlySettings/PayrollYearlySettingsDetails';
import SA100Configuration from '../Components/SA100Configuration/SA100Configuration';


export const adminContext = React.createContext<any>(null);

const PageRoute: React.FC = () => {

    type NotificationType = 'success' | 'info' | 'warning' | 'error';
    //const { isAuthenticated } = useUser();
    const [ practiceData, setPracticeData] = React.useState<any>(undefined);
    const [payrollYearlySettingsData, setPayrollYearlySettingsData] = React.useState<any>(undefined);
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (type: NotificationType, message: string | undefined) => {
        api[type]({
            message: '',
            description: message,
            duration: 4,
            style: {
                width: 400,
                top: 18,
                borderRadius: 0,
                padding:"20px 12px"
            }
        })
    };

    const location = useLocation();

    React.useEffect(() => {
        const path = location.pathname;
        let title = 'Acting Office'; // Set a default title
        if (path.startsWith('/profile')) {
            title = `Profile-${title}`;
        } else if (path.startsWith('/practices')) {
            title = `Practices-${title}`;
        } else if (path.startsWith('/dashboard')) {
            title = `Dashboard-${title}`;
        } else if (path.startsWith('/updates')) {
            title = `Updates-${title}`;
        } else if (path.startsWith('/ctconfiguration')) {
            title = `CTConfiguration-${title}`;
        } else if (path.startsWith('/payrollyearlysettings')) {
            title = `Payroll Yearly Settings-${title}`;
        }
        

        document.title = title;
    }, [location]);


    return (
        <adminContext.Provider value={{ openNotification, practiceData, setPracticeData, payrollYearlySettingsData, setPayrollYearlySettingsData }}>
            <Routes>
                 <Route path='/' element={<Dashboard/>} />
                 <Route path='/dashboard' element={<Dashboard/>} />
                 <Route path='/profile' element={<Profile/>} />
                 <Route path='/practices' element={<Practices />} />
                 <Route path='/practices/:id' element={<PracticeDetails data={practiceData} />} />
                 <Route path='/updates' element={<Updates/>} />
                 <Route path='/ctconfiguration' element={<CTConfiguration/>} />
                 <Route path='/payrollyearlysettings' element={<PayrollYearlySettings/>} />
                 <Route path='/payrollyearlysettings/:id' element={<PayrollYearlySettingsDetails data={payrollYearlySettingsData} />} />
                 <Route path='/sa100configuration' element={<SA100Configuration/>} />
            </Routes>
            {contextHolder}
        </adminContext.Provider>    
    )
}
export default PageRoute;