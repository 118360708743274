import React, { useEffect, useState } from "react";
import {
  Layout,
  Image,
  Input,
  Row,
  Avatar,
  Popover,
  Button,
  Typography,
  Col,
  Modal,
  Form,
} from "antd";
import CollapseImg from "../images/nav-bar-icon.svg";
import newLogoImg from "../images/aoLogo30px.svg";
import newLogosmImg from "../images/AOIcon.svg";
import PageRoute from "./PageRoute";
import "./MainLayout.css";
import Sidemenus from "./Sidemenus";
import { LogoutOutlined, UserOutlined, SyncOutlined,  SaveOutlined } from "@ant-design/icons";
import { AuthService } from "../Services/AuthService";
import { useNavigate } from "react-router-dom";
import { useUser } from "../Context/UserContext";
import UnAuthRoute from "./UnAuthRoute";
const { Header, Sider, Content } = Layout;

const MainLayout: React.FC = () => {
  const { isAuthenticated, isSuperAdmin, currentUser } = useUser();
  const userName: any = currentUser?.name.get();
  const userEmail: any = currentUser?.email.get();
  const userId: any = currentUser?.id.get();
  // const userId: any = currentUser?.id.get();
  // const employeeId: any = getEmployeeId();
  const [imagePath, setImagePath] = useState(null);

  const contentUser = () => {
    return (
      <div style={{ width: 295 }}>
        <Row align="middle" className="mt-3 mb-2 ps-2">
          {imagePath ? (
            <Avatar src={imagePath} className="avatar-user cursor" />
          ) : (
            <Avatar icon={<UserOutlined />} className="avatar-user cursor" />
          )}
          <Col className="ps-2">
            <Typography.Title level={5} className="mb-2" style={{ margin: 0 }}>
              {userName}
            </Typography.Title>
            {/* </Row>
            <Row justify="center" className="mb-2"> */}
            {userEmail}
          </Col>
        </Row>

        <Row className="py-2 ps-2" align="middle">
          <Col className="cursor">
            <Button
              type="text"
              size="small"
              className="linkcolor"
              icon={<UserOutlined />}
              onClick={() => {
                navigate("/profile");
                setOpenPopover(false);
              }}
            >
              Profile
            </Button>
          </Col>
        </Row>

        <Row className="py-2 ps-2" align="middle">
          <Col className="cursor">
            <Button
              type="text"
              size="small"
              className="linkcolor"
              icon={<SyncOutlined />}
              onClick={() => {
                setOpenChangePassword(true);
                setOpenPopover(false);
              }}
            >
              Change Password
            </Button>
          </Col>
        </Row>

        <Row className="py-2 ps-2" align="middle">
          <Col className="cursor">
            <Button
              type="text"
              size="small"
              className="linkcolor"
              icon={<LogoutOutlined rotate={-90} />}
              onClick={() => {
                UserLogOut();
                setOpenPopover(false);
              }}
            >
              Logout
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const navigate = useNavigate();
//   const location = useLocation();
  const [form] = Form.useForm();
  const UserLogOut = () => {
    AuthService.logout().then((user) => {
      navigate("/login");
      setImagePath(null);
    });
  };
  const [collapsed, setCollapsed] = useState(false);
  const style = {
    position: "absolute",
    top: window.innerWidth <= 1200 ? "40px" : "65px",
    width: "255px",
    height: "100%",
    zIndex: "1",
  };
  const [width, setWidth] = useState(window.innerWidth);
  const [slide, setSlide] = useState(false);
  const [serachInput, setSearchInput] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [btnLoading, setBtnLoading] = useState(false);

  const onCollapse = () => {
    if (window.innerWidth >= 1200) {
      setCollapsed((x) => !x);
    }
    if (window.innerWidth <= 1200) {
      setSlide((x) => !x);
    }
  };

  const handleResize = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth < 1200) {
      setSlide(false);
    }
    if (window.innerWidth > 1200) {
      setSearchInput(false);
    }
  };

  const [openPopover, setOpenPopover] = useState(false);

  useEffect(() => {
    // var splitPath = location.pathname.split("/");
    if (!isAuthenticated()) {
      navigate("/login");
    }
    window.addEventListener("resize", handleResize, false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const logoStyle = {
    display: "flex",
    justifyContent: collapsed ? "center" : "start",
    paddingLeft: collapsed ? "0px" : "20px",
    alignItems: "center",
    height: "44px",
  };
React.useEffect(()=>{
    if(window.innerWidth >= 1400){
        setCollapsed(false);
    } else {
        setCollapsed(true);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[window.innerHeight])
  return (
    <React.Fragment>
      {/* model for password change */}
      <Modal
        destroyOnClose
        title="Change Password"
        width="400px"
        open={openChangePassword}
        onCancel={() => {
          setOpenChangePassword(false);
          form.resetFields();
        }}
        onOk={()=>{}}
        footer={
          <Button
            className="mt-2"
            type="primary"
            size="middle"
            loading={btnLoading}
            onClick={() =>{
              form.validateFields().then(async (values:any)=>{
                if(userId !== undefined || userId !== ""  ||userId !==  null){
                  let res = await AuthService.changePassword(userId, values?.currentPassword, values?.newPassword, values?.confirmPassword);
                  if(res){
                    setOpenChangePassword(false);
                    form.resetFields();
                  } 
                } else{
                  // openNotification("error", "user not found");
                }
              })
            }}
          >
            <SaveOutlined /> Submit
          </Button>
        }
      >
        <Form
          form={form}
          name="change-password"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          autoComplete="off"
          colon={false}
          size="small"
        >
          <Row className="border-bottom" style={{ marginBottom: 18 }}></Row>
          <Form.Item
            label="Current password"
            name="currentPassword"
            labelAlign="left"
            className="form-item-input"
            rules={[{ required: true, message: "This filed is required" }]}
          >
            <Input.Password className="borderLessInput w100" />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            labelAlign="left"
            className="form-item-input"
            rules={[{ required: true, message: "This filed is required" }]}
          >
            <Input.Password className="borderLessInput w100" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            labelAlign="left"
            className="form-item-input"
            rules={[
              { required: true, message: "This filed is required" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Password do not match!"));
                },
              }),
            ]}
          >
            <Input.Password className="borderLessInput w100" />
          </Form.Item>
        </Form>
      </Modal>

      {isAuthenticated() ? (
        <Layout hasSider>
          {width >= 767 && (
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={{
                backgroundColor: "#fff",
                overflow: "auto",
                height: "100vh",
                position: "fixed",
                left: 0,
                top: 0,
                bottom: 0,
                zIndex: 2,
              }}
            >
              <div className="logo" style={logoStyle}>
                <img
                  src={collapsed ? newLogosmImg : newLogoImg}
                  height="28px"
                  className="cursor"
                  alt="Logo"
                  onClick={() => {
                    // navigate("/dashboard");
                    onCollapse();
                  }}
                />
              </div>
              <Sidemenus
                isCollapse={collapsed}
                onsetSlide={setSlide}
                styles={undefined}
                isSlide={undefined}
              />
            </Sider>
          )}
          <Layout
            className="site-layout"
            style={
              collapsed
                ? { width: "calc(100%-60px)", marginLeft: "60px", }
                : { width: "calc(100%-255px)", marginLeft: "255px" }
            }
          >
            <Header className="site-layout-background aoa-layout">
              <div style={{ display: "flex", alignItems: "center" }}>
                {!isSuperAdmin() && (
                  <>
                    <div className="logo-min">
                      <img
                        src={newLogosmImg}
                        height="36px"
                        className="cursor"
                        alt="Logo"
                        onClick={onCollapse}
                      />
                    </div>
                    <Image
                      src={CollapseImg}
                      preview={false}
                      className="trigger cursor"
                      rootClassName="layout-image"
                      onClick={onCollapse}
                    />
                  </>
                )}
                <div className="appheading">Acting Office - Super Admin</div>
                {/* <Input placeholder="Search..." suffix={<SearchOutlined style={{ color: "#d9d9d9" }} />} style={{ width: '230px', height: '32px', marginLeft: '35px' }} className='headerSearch' />*/}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Popover
                  placement="bottomRight"
                  content={contentUser}
                  trigger="click"
                  open={openPopover}
                  onOpenChange={(val: boolean) => {
                    setOpenPopover(val);
                  }}
                >
                  <Avatar
                    icon={<UserOutlined />}
                    className="avatar-user"
                    onClick={() => {
                      //setOpenPopover((x: any) => !x);
                    }}
                  />
                </Popover>
              </div>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: "2px 15px",
                minHeight: 280,
              }}
            >
              {<PageRoute />}
            </Content>
          </Layout>
          {slide && (
            <Sidemenus
              isCollapse={collapsed}
              onsetSlide={setSlide}
              styles={style}
              isSlide={slide}
            />
          )}
          {serachInput && (
            <Row className="searchBar">
              <Input placeholder="Search..." />
            </Row>
          )}
        </Layout>
      ) : (
        <Layout className="site-layout">
          <Content>
            <UnAuthRoute />
          </Content>
        </Layout>
      )}
    </React.Fragment>
  );
};

export default MainLayout;
