import { ApiUtility } from "../Utilities/ApiUtility.axios";

export enum ReleaseUpdateTypes
{
    Unknown = 0,
    Enhancement = 1,
    New = 2,
    Fix = 3
}
class UserService{
    route = `/Api/User`;

    getUserDetails = (id:string) => ApiUtility.get(`${this.route}/${id}`);

    updateUserDetails = (id:string, params:any) => ApiUtility.post(`${this.route}/${id}`, params);
}
export default new UserService();